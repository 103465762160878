import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import styles from '../sass/components/checkbox.module.scss';
import getString from '../utils/get-string';

const Checkbox = ({ value, name, required = false, onChange, children, strings }) => (
  <label className={styles.checkbox}>
    <input
      type="checkbox"
      className={styles.checkbox__input}
      name={name || value}
      required={required}
      onChange={onChange && (event => onChange(event))}
      value={value}
    />
    <span className={styles.checkbox__caption}>{children || value}</span>
    <p className="validation">{getString(strings, "11220910")}</p>
  </label>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        strings: allDatoCmsLanguageString(filter: {originalId: {in: ["11220910"]}}) {
          edges {
            node {
              string
              original
              originalId
            }
          }
        }
      }
    `}
    render={data => <Checkbox {...data} {...props} />}
  />
)
