import { navigate, graphql, StaticQuery } from 'gatsby';
import md5 from 'md5';
import React from 'react';

import Checkbox from '../components/checkbox';
import { Form, FormError, FormForgotten, FormHeader, FormItems, FormSubmit } from '../components/form';
import Input from '../components/input';
import Layout from '../components/layout';
import Link from '../components/link';
import { Content, ContentDesc, Footer, FooterLinkBack, Header, Page } from '../components/page';
import Seo from '../components/seo';
import SvgArrowRight from '../svg/arrowRight';
import datoCmsGrahpQl from '../utils/dato-cms-graphql';
import { createOrUpdateUserData } from '../utils/userData';
import getString from '../utils/get-string';

class Login extends React.Component {
  state = {
    form: {},
    submitProcessing: false,
    user: undefined,
    rememberUser: false,
  }

  submitHandler = event => {
    this.setState({ submitProcessing: true })
    this.checkUserExists()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  checkUserExists = () => {
    const query = `expert(filter: {email: {eq: "${
      this.state.form.email
    }"}, password: {eq: "${this.state.form.password}"}}) {
      name
      email
      id
      lessons
      emailisverified
    }`

    return new Promise(resolve => datoCmsGrahpQl(query, resolve)).then(
      ({ expert }) => {
        if (this._isMounted)
          this.setState({
            user: expert ? true : false,
            submitProcessing: false,
          })
        if (this._isMounted && expert) this.handleSuccessLogin(expert)
      }
    )
  }

  handleSuccessLogin = userData => {
    if (!userData.emailisverified) {
      navigate(`/email-not-confirmed`, { state: { ...userData } })
      return
    }

    createOrUpdateUserData({
      name: userData.name,
      lessons: userData.lessons,
      rememberUser: this.state.rememberUser,
      userId: userData.id,
      email: userData.email,
    })

    navigate(
      (this.props.location.state && this.props.location.state.pageAfterLogin) ||
        '/lessons'
    )
  }

  componentDidMount() {
    this._isMounted = true
  }

  handleChange = event => {
    const name = event.target.name
    const value =
      event.target.type !== 'checkbox' || event.target.type !== 'radio'
        ? event.target.type === 'password'
          ? md5(event.target.value)
          : event.target.value
        : event.target.checked

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
    }))
  }

  handleRemember = event => {
    this.setState({ rememberUser: event.target.checked })
  }

  render() {
    //if (getUserData()) clearUserData()

    return (
      <Layout>
        <Seo title={getString(this.props.strings, "11220911")} />

        <Page type="short">
          <Form onSubmit={this.submitHandler}>
            <Header closeUrl="/lessons">
              {(this.props.location.state &&
                this.props.location.state.loginText && (
                  <span className="_color-danger">
                    {this.props.location.state.loginText}
                  </span>
                )) ||
                getString(this.props.strings, "11220916")}
            </Header>
            <Content>
              <FormHeader>
                <ContentDesc>
                  {(this.props.location.state &&
                    this.props.location.state.loginText && (
                      <span className="_color-danger">
                        {this.props.location.state.loginText}
                      </span>
                    )) ||
                    getString(this.props.strings, "11220916")}
                </ContentDesc>
                <h1>{getString(this.props.strings, "11220916")}</h1>
              </FormHeader>

              <FormItems>
                <dt>
                  <label htmlFor="email">{getString(this.props.strings, "11220833")}</label>
                </dt>
                <dd>
                  <Input
                    size="lg"
                    id="email"
                    type="email"
                    name="email"
                    required={true}
                    onChange={this.handleChange}
                  />
                  <p className="validation">{getString(this.props.strings, "11220910")}</p>
                </dd>

                <dt>
                  <label htmlFor="password">{getString(this.props.strings, "11220900")}</label>
                </dt>
                <dd>
                  <Input
                    size="lg"
                    id="password"
                    type="password"
                    name="password"
                    required={true}
                    onChange={this.handleChange}
                  />
                  <p className="validation">{getString(this.props.strings, "11220910")}</p>
                </dd>
              </FormItems>

              <FormForgotten>
                <Link to="/forgotten-password">
                  {getString(this.props.strings, "11220922")} <SvgArrowRight />
                </Link>
              </FormForgotten>

              <Checkbox value={getString(this.props.strings, "11220921")} onChange={this.handleRemember} />

              {this.state.user !== undefined && !this.state.user && (
                <FormError>{getString(this.props.strings, "11220924")}</FormError>
              )}
            </Content>
            <Footer>
              <FormSubmit disabled={this.state.submitProcessing}>
                {getString(this.props.strings, "11220911")}
              </FormSubmit>
              <FooterLinkBack to="/register">
                {getString(this.props.strings, "11220923")}
                <SvgArrowRight />
              </FooterLinkBack>
            </Footer>
          </Form>
        </Page>
      </Layout>
    )
  }
}


export default props => (
  <StaticQuery
    query={graphql`
      query {
        strings: allDatoCmsLanguageString(filter: {originalId: {in: ["11220911", "11220916", "11220833", "11220910", "11220900", "11220922", "11220924", "11220921", "11220923"]}}) {
          edges {
            node {
              string
              original
              originalId
            }
          }
        }
      }
    `}
    render={data => <Login {...data} {...props} />}
  />
)
